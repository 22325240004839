import React, { useState, useEffect } from 'react';
import styles from './LocationSelect.module.scss';
import { Button, Container, P, H4 } from '../../styled/components';
import { useHistory } from 'react-router-dom';
import { API, graphqlOperation } from 'aws-amplify';
import { getNurseAuthToken } from '../../Shared';
import { listLocalHealthDistricts, listHospitals, listWards } from '../../graphql/queries';
import Dropdown from '../../components/shared/dropdown/Dropdown';
import Notification from '../../components/shared/notifications/Notification';
import FadeLoader from 'react-spinners/FadeLoader';
import { css } from '@emotion/react';

import { 
    setHospital,
    setDistrict,
    setWard,
    setWardId
} from '../../store/actions/location';
import { useSelector, useDispatch } from 'react-redux';
const override = css`
    position: fixed;
    left: 50%;
    top: 50%;
`;

const LocationSelect = () => {
    const history = useHistory();
    const [fail, setFail] = useState(false);
    const [loading, setLoading] = useState(false);
    const emptyList = [];

    const [locationData, setLocationData] = useState({});
    
    const [district, setDistrictLocal] = useState('Select');
    const [districtList, setDistrictList] = useState(emptyList);
    
    const [hospital, setHospitalLocal] = useState('Select');
    const [hospitalList, setHospitalList] = useState(emptyList);
    
    const [ward, setWardLocal] = useState('Select');
    const [wardList, setWardList] = useState(emptyList);

    const lastDistrict = useSelector(state => state.location.district);
    const lastHospital = useSelector(state => state.location.hospital);
    const lastWard = useSelector(state => state.location.ward);

    const dispatch = useDispatch();

    useEffect(async () => {
        let d = getHealthDistricts();
        let h = getHospitals();
        let w = getWards();
        setLoading(true);
        const [districts, hospitals, wards] = await Promise.all([d, h, w]);
        setLoading(false);

        if (d && h && w) {
            setLocationData({ districts, hospitals, wards });
            setDistrictList(districts);
        }
        setDistrictLocal(lastDistrict);
        setHospitalLocal(lastHospital);
        setWardLocal(lastWard);

    }, []);

    useEffect(() => {
        if (district !== 'Select' && locationData.districts) {
            const matchedDistrict = districtList.filter(d => d.lcName === district);
            if (!matchedDistrict.length) return;
            const districtId = matchedDistrict[0].lcID;
            setHospitalList(locationData.hospitals.filter(h => h.lcParent === districtId));
        }        
    }, [district]);

    useEffect(() => {
        if (hospital !== 'Select' && locationData.hospitals) {
            const matchedHospital = hospitalList.filter(d => d.lcName === hospital);
            if (!matchedHospital.length) return;
            const hospitalId = matchedHospital[0].lcID;
            setWardList(locationData.wards.filter(h => h.lcParent === hospitalId));
        }
    }, [hospital]);
    
    const getHealthDistricts = async () => {
        try {
            const districtsRes = await API.graphql(graphqlOperation(listLocalHealthDistricts), { 'Authorization': getNurseAuthToken() });
            console.log(districtsRes);
            return districtsRes.data.listLocalHealthDistricts;
        } catch (err) {
            setFail(true);
            console.error(err);
            return false;
        }
    };
    const getHospitals = async () => {
        try {
            const hospitalRes = await API.graphql(graphqlOperation(listHospitals), { 'Authorization': getNurseAuthToken() });
            console.log(hospitalRes);
            return hospitalRes.data.listHospitals;
        } catch (err) {
            setFail(true);
            console.error(err);
            return false;
        }
    };
    const getWards = async () => {
        const wardRes = await API.graphql(graphqlOperation(listWards), { 'Authorization': getNurseAuthToken() });
        try {
            console.log(wardRes);
            return wardRes.data.listWards;
            
        } catch (err) {
            setFail(true);
            console.error(err);
            return false;
        }
    };
    
    const nextClicked = async () => {
        const wardId = locationData.wards.filter(w => w.lcName === ward)[0].lcID;
        dispatch(setDistrict(district));
        dispatch(setHospital(hospital));
        dispatch(setWard(ward));
        dispatch(setWardId(wardId));
        history.replace('/loginpatient');
    };

    const updateDistrict = (val) => {
        if (district !== val) {
            setDistrictLocal(val);
            setHospitalLocal('Select');
            setWardLocal('Select');
        }
        //setDistrict(event);
    };
    const updateHospital = (val) => {
        if (hospital !== val) {
            setHospitalLocal(val);
            setWardLocal('Select');
        }
    };
    const updateWard = (val) => {
        if (ward !== val) setWardLocal(val);
    };

    const invalidLocation = (locationList, name) => {
        if (!locationList.length) return true;
        const valid = locationList.filter(l => l.lcName === name);
        return !valid.length;
    };
    const disableButton = invalidLocation(districtList, district) || invalidLocation(hospitalList, hospital) || invalidLocation(wardList, ward);

    return (
        <Container>
            { fail && <Notification warning message={'Sorry, locations could not be fetched. Please ty again later'} />}
            <div className={styles.container}>
                <div>
                    <H4 style={{marginTop: '1.7rem', marginBottom: '0.6rem'}}>Choose Location</H4>
                    <P>To set up device, choose your ward by first filtering on your LHD and Hospital</P>     
                    <Dropdown label={'Local Health District'} selected={district} options={districtList.map(d => d.lcName)} selectedHandler={updateDistrict} required={true}/>
                    <Dropdown label={'Hospital'} selected={hospital} options={hospitalList.map(h => h.lcName)} selectedHandler={updateHospital} required={true}/>
                    <Dropdown label={'Ward'} selected={ward} options={wardList.map(h => h.lcName)} selectedHandler={updateWard} required={true}/>
                </div>            
                <Button onClick={nextClicked} isDisabled={disableButton} disabled={disableButton} className={styles.nextButton}>Next</Button>
            </div>
            <FadeLoader loading={loading} css={override}/>
        </Container>
    );
};

export default LocationSelect;