export const getTimestampToTheHour = (time) => {
    if (time === 'Indefinite') {
        return null;
    }
    else {
        let hours = parseInt(time.match(/^(\d+)/)[0]);
        let mins = parseInt(time.match(/:(\d+)/)[1]);
        let AMPM = time.match(/[a-zA-Z]+/)[0];
        if (hours === 12) hours = 0;
        if (AMPM.toLowerCase() === 'pm') hours += 12;
            
        const date = new Date();
        date.setHours(hours);
        date.setMinutes(mins);
        date.setSeconds(0);
        return Math.floor(date/1000);
    }
};

export const makeTimes = () => {
    let increment = 30;
    let times = [];
    let startTime = 0;

    for (let i = 0; startTime < 24*60; i++) {
        let time = makeTime(startTime);
        times[i] = time;
        startTime += increment;
    }
    return times.slice(0, 48);
};

const makeTime = (startTime) => {
    let ap = ['am', 'pm'];
    let hh = Math.floor(startTime/60);
    let mm = (startTime%60);
    let time = parseInt(('0' + (hh % 12)).slice(-2)) + ':' + ('0' + mm).slice(-2) + ap[Math.floor(hh/12)]; 
    if(hh === 0 || hh === 12) time = time.replace('0:', '12:');
    return time;
};

export const dateToHourString = (date) => {
    let time = date.getHours();
    if(time === 12) time += ':00pm';
    else if(time > 12){ 
        time -= 12;
        time += ':00pm';
    }
    else if (time === 0) time = '12:00am';
    else {
        time += ':00am';
    }
    return time;
};