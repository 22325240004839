import React, { useEffect, useRef } from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import { refreshToken, unassignDevices } from './graphql/mutations';
import { getNurseAuthToken, getDeviceAuthToken } from './Shared';
import { useLocation } from 'react-router-dom';
import Login from './pages/login/Login';
import Landing from './pages/landing/Landing';
import LoginPatient from './pages/loginPatient/LoginPatient';
import LocationSelect from './pages/locationSelect/LocationSelect';
import NewPatient from './pages/newPatient/NewPatient';
import PatientSetup from './pages/patientSetup/PatientSetup';
import WaitingRoom from './pages/call/WaitingRoom';
import Header from './components/shared/header/Header';
import Calling from './pages/call/Calling';
import InCall from './pages/call/InCall';
import Call from './pages/call/Call';
import {
    BrowserRouter,
    Switch,
    Route
} from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setNurseToken } from './store/actions/app';
import { useSelector } from 'react-redux';

function App() {
    const tokenRefresherRaf = useRef(null);
    const location = useLocation();
    const patientId = useSelector(state => state.app.patientId);

    // Scroll to top of page when route changes
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location]);

    const dispatch = useDispatch();

    useEffect(async () =>  {
        function cancelAllAnimationFrames(){
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            var id = window.requestAnimationFrame(()=>{});
            while(id--){
                window.cancelAnimationFrame(id);
            }
        }
        cancelAllAnimationFrames();
        
        const fifteenMins = 900000;
        tokenRefresherRaf.current = tokenRefresher(Date.now(), fifteenMins, tokenRefresherRaf);
        return () => cancelAnimationFrame(tokenRefresherRaf.current);
    }, []);

    const tokenRefresher = async (start, refreshInterval, ref) => {
        if (start + refreshInterval < Date.now() && getNurseAuthToken() !== 'nurse-') {
            await getToken();
            start = Date.now();
        }
        await new Promise(resolve => setTimeout(resolve, 1000));
        ref.current = requestAnimationFrame(() => tokenRefresher(start, refreshInterval, ref));
    };

    const getToken = async () => {
        try {
            const authResponse = await API.graphql(graphqlOperation(refreshToken), { 'Authorization': getNurseAuthToken() });
            if(authResponse.data.refreshToken.statusCode !== 200) kickOut();
            dispatch(setNurseToken(authResponse.data.refreshToken.body.jwt));
        } catch (err) {
            console.error(err);
            kickOut();
        }
    };

    const kickOut = async () => {
        await unassignDevice();
        window.location = '/';
    };

    const unassignDevice = async () => {
        try {
            const unassignResponse = await API.graphql(graphqlOperation(unassignDevices, {
                ptID: patientId
            }), { 'Authorization': getDeviceAuthToken() });
            console.log(unassignResponse);
        }
        catch (err) {
            console.error(err);
        }
    };
    
    return (
        <div>
            <BrowserRouter>
                <Header />
                <Switch>
                    <Route path="/call">
                        <Call />
                    </Route>
                    <Route path="/calling">
                        <Calling />
                    </Route>
                    <Route path="/incall">
                        <InCall />
                    </Route>
                    <Route path="/waitingroom">
                        <WaitingRoom />
                    </Route>
                    <Route path="/loginpatient">
                        <LoginPatient />
                    </Route>
                    <Route path="/login">
                        <Login />
                    </Route>
                    <Route path="/location">
                        <LocationSelect />
                    </Route>
                    <Route path="/newpatient">
                        <NewPatient/>
                    </Route>
                    <Route path="/patientsetup">
                        <PatientSetup/>
                    </Route>
                    <Route path="/landing">
                        <Landing />
                    </Route>
                    <Route path="/">
                        <Login />
                    </Route>
                </Switch>
            </BrowserRouter>
        </div>
    );
}

export default App;
