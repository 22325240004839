import React, { useState, useEffect, useRef } from 'react';
import styles from './InCall.module.scss';
import Modal from '../../components/shared/modal/Modal';
import FadeLoader from 'react-spinners/FadeLoader';
import Switch from '../../components/shared/switch/Switch';
import { useSelector } from 'react-redux';
import {
    H5, CallButton, P
} from '../../styled/components';

const InCall = ({
    handleCall, 
    pexipVideoRef, 
    incomingCallQueue, 
    participants, 
    handleIncomingCall, 
    handleCamera, 
    handleAudio, 
    removeParticipant,
    autoAnswer,
    autoAnswerToggle,
    medicalEndCall,
    selfViewUrl,
    isPresenting,
    presentingUrl
}) => {
    const [audioMuted, setAudioMuted] = useState(false);
    const [videoMuted, setVideoMuted] = useState(false);
    // const [incomingCallModal, setIncomingCallModal] = useState(false);
    const [participantsModal, setParticipantsModal] = useState(false);
    const [reconnectingModal, setReconnectingModal] = useState(false);
    const [reconnecting, setReconnecting] = useState(false);
    const [modalTimeout, setModalTimeout] = useState(false);
    const [imgSrc, setImgSrc] = useState('');

    const timeoutRef = useRef(null);
    const selfViewRef = useRef(null);

    const lastSelfUrl = useSelector(state => state.app.selfUrl);

    useEffect(() => {
        setImgSrc(presentingUrl);
    }, [presentingUrl]);

    useEffect(() => {
        try {
            selfViewRef.current.srcObject = selfViewUrl;
        } catch {
            selfViewRef.current.src = lastSelfUrl;
        }
    }, [selfViewUrl]);

    useEffect(() => {
        if (modalTimeout) {
            timeoutRef.current = setTimeout(() => {
                setModalTimeout(false);
            }, 500);
        }
    }, [modalTimeout]);

    useEffect(() => {
        return () => clearTimeout(timeoutRef.current);
    }, []);

    useEffect(() => {
        console.log('handleCall');
    },[handleCall]);

    useEffect(() => {
        handleAudio(audioMuted);
    }, [audioMuted]);

    useEffect(() => {
        handleCamera(videoMuted);
    }, [videoMuted]);

    const fullScreen = () => {
        if (pexipVideoRef.current.requestFullscreen) {
            pexipVideoRef.current.requestFullscreen();
        } else if (pexipVideoRef.current.webkitRequestFullscreen) { /* Safari */
            pexipVideoRef.current.webkitRequestFullscreen();
        } else if (pexipVideoRef.current.msRequestFullscreen) { /* IE11 */
            pexipVideoRef.current.msRequestFullscreen();
        }
    };

    const reconnect = () => {
        setReconnecting(true);
        setTimeout(() => {
            setReconnectingModal(false);
        }, 2000);
    };

    return (
        <div className={styles.container}>
            {reconnectingModal && <Modal 
                buttonLabel1={reconnecting ? '' : 'Retry'} 
                handleButton1={()=>reconnect()}
                title={'Reconnecting, Hold on'}
                copy={'Looks like something went wrong. We\'re trying to get you back on the call'}
                contentHTML={reconnecting && <FadeLoader loading={true} css={'left:50%;height:0.2rem;'} />}
            />}
            {incomingCallQueue.length > 0 && !modalTimeout && <Modal 
                buttonLabel1={'Accept Call'} 
                buttonLabel2={'Reject Call'} 
                handleButton1={ () => { handleIncomingCall(true); setModalTimeout(true); } }
                handleButton2={ () => { handleIncomingCall(false); setModalTimeout(true); } }
                title={'Incoming Call'} 
                copy={incomingCallQueue[0].display_name + ' wants to join the call'}
            />}
            {participantsModal && <Modal 
                buttonLabel1={'Return to call'} 
                handleButton1={()=>setParticipantsModal(false)}
                title={`${participants.length} participant${participants.length !== 1 ? 's' : ''} in the room`} 
                contentHTML={
                    <div className={styles.modalRowContainer}>
                        {participants.map((participant, i) => 
                            <div key={i} className={styles.modalRow}>
                                <H5>{participant.display_name}</H5>
                                <button onClick={() => removeParticipant(participant)}>Remove</button>
                            </div>)}
                    </div>
                }
            />}
            <div className={styles.header}>
                <div className={styles.headerTop}>
                    <div  className={styles.headerTopFlex}>
                        <img className={styles.logo} style={{flex:1}} src="/logo-white.png" alt="logo" />
                    </div>
                    <div>
                        <img src="/visitation-logo.png" className={styles.visitation_logo} />
                    </div>
                    <div style={{flex:1, justifyContent: 'flex-end'}} className={styles.row}>
                        <div>
                            <H5 style={{textAlign: 'right'}}>Medical End Call</H5>
                            <P style={{color: 'white', fontSize: '0.8rem', margin: '0.3rem 0 0 0', textAlign: 'right'}}>End patient call and notify visitors</P>
                        </div>
                        <CallButton style={{marginLeft: '1rem', flex: 'none'}} width={'4rem'} height={'4rem'} onClick={() => medicalEndCall()}>
                            <img style={{width: '2rem'}} src="/medical-call-end.png" alt="Medical End Call" />
                        </CallButton>
                    </div>
                </div>
                <div className={styles.headerBottom}>
                    <div style={{marginRight: '1rem'}} className={styles.row}>
                        <H5 style={{marginRight: '1rem'}} className={styles.autoAnswerText}>Enable Auto-Answer<br/>for incoming calls</H5>
                        <Switch checked={autoAnswer} onChange={autoAnswerToggle} />
                    </div>
                    <div className={styles.row}>
                        <CallButton style={{marginRight: '1rem'}} width={'7rem'} height={'4rem'} borderRadius={'2rem'} black border onClick={() => fullScreen()}>
                            Full Screen
                        </CallButton>
                        <CallButton width={'4rem'} height={'4rem'} black border onClick={() => setParticipantsModal(true)}>
                            <img style={{width: '1.5rem'}} src="/person.png" alt="People in call" />
                            {participants.length > 0 && <div className={styles.participants}>{participants.length}</div>}
                        </CallButton>
                    </div>
                </div>
            </div>
            <div className={styles.videoContainer}>
                {isPresenting && <img className={styles.video} src={imgSrc}/>}
                <video className={isPresenting ? styles.presentationView : styles.video} ref={pexipVideoRef} autoPlay="autoplay"></video>
                <video className={styles.selfView} ref={selfViewRef} autoPlay="autoplay" muted={true}></video>
            </div>
            <div className={styles.footer}>
                <div className={styles.row}>
                    <CallButton className={styles.callButtonSmall} black border onClick={() => setAudioMuted(!audioMuted)}>
                        <img className={styles.iconMic} src="/microphone.png" alt="Microphone" />
                        <div className={`${styles.line} ${audioMuted ? styles.lineOff : ''}`}></div>
                    </CallButton>
                    <CallButton className={styles.callButtonLarge} onClick={() => handleCall()}>
                        <img className={styles.iconPhone} src="/phone-end.png" alt="End Call" />
                    </CallButton>
                    <CallButton className={styles.callButtonSmall} black border onClick={() => setVideoMuted(!videoMuted)}>
                        <img className={styles.iconCamera} src="/camera.png" alt="Camera" />
                        <div className={`${styles.line} ${videoMuted ? styles.lineOff : ''}`}></div>
                    </CallButton>
                </div>
            </div>
        </div>
    );
};

export default InCall;
