import { 
    SET_NURSE_TOKEN,
    SET_DEVICE_TOKEN,
    SET_DEVICE_ID,
    SET_MRN,
    SET_PATIENT_ID,
    SET_PATIENT_NAME,
    SET_START_TIME,
    SET_END_TIME,
    SET_VISITOR_LIST,
    SET_DIAL_STRING, 
    SET_AUTO_ANSWER,
    SET_CALL_PAGE,
    SET_SELF_URL,
} from '../constants';

export const setNurseToken = (token) => {	
    return {
        type: SET_NURSE_TOKEN,
        payload: token
    };
};

export const setDeviceToken = (token) => {	
    return {
        type: SET_DEVICE_TOKEN,
        payload: token
    };
};

export const setDeviceId = (id) => {	
    return {
        type: SET_DEVICE_ID,
        payload: id
    };
};

export const setMRN = (MRN) => {	
    return {
        type: SET_MRN,
        payload: MRN
    };
};

export const setPatientId = (id) => {	
    return {
        type: SET_PATIENT_ID,
        payload: id
    };
};

export const setPatientName = (name) => {	
    return {
        type: SET_PATIENT_NAME,
        payload: name
    };
};

export const setStartTime = (time) => {	
    return {
        type: SET_START_TIME,
        payload: time
    };
};

export const setEndTime = (time) => {	
    return {
        type: SET_END_TIME,
        payload: time
    };
};

export const setVisitorList = (list) => {	
    return {
        type: SET_VISITOR_LIST,
        payload: list
    };
};

export const setDialString = (dialString) => {	
    return {
        type: SET_DIAL_STRING,
        payload: dialString
    };
};

export const setAutoAnswer = (autoAnswer) => {	
    return {
        type: SET_AUTO_ANSWER,
        payload: autoAnswer
    };
};

export const setCallPage = (callPage) => {	
    return {
        type: SET_CALL_PAGE,
        payload: callPage
    };
};

export const setSelfUrl = (url) => {	
    return {
        type: SET_SELF_URL,
        payload: url
    };
};