import React, { useState, useEffect, useRef } from 'react';
import {
    Button,
    Container,
    P, H4, H5
} from '../../styled/components';
import FadeLoader from 'react-spinners/FadeLoader';
import { css } from '@emotion/react';
import Dropdown from '../../components/shared/dropdown/Dropdown';
import InputField from '../../components/shared/input/InputField';
import Notification from '../../components/shared/notifications/Notification';
import { API, graphqlOperation } from 'aws-amplify';
import { listPatients } from '../../graphql/queries';
import { getNurseAuthToken } from '../../Shared';
import { makeTimes } from '../../utils';
import { useHistory } from 'react-router-dom';
import * as actions from '../../store/actions/app';
import { useDispatch, useSelector } from 'react-redux';

const override = css`
    position: fixed;
    left: 50%;
    top: 50%;
`;

const LoginPatient = () => {
    const history = useHistory();
    const [MRN, setMRN] = useState('');
    const [patientName, setPatientName] = useState('');
    const [patients, setPatients] = useState([]);
    const [contacts, setContacts] = useState([]);
    const [selectedPatientId, setSelectedPatientId] = useState(null);
    const [errorMessage, setErrorMessage] = useState('');
    const [startTime, setStartTime] = useState('Select');
    const [endTime, setEndTime] = useState('Select');
    const [loading, setLoading] = useState(false);
    const [loginDisabled, setLoginDisabled] = useState(true);
    const [autoAnswer, setAutoAnswer] = useState(false);
    const startTimes = makeTimes();
    const endTimes = ['Indefinite', ...makeTimes()];
    const wardId = useSelector(state => state.location.wardId);

    const errorTimeoutRef = useRef(null);

    const dispatch = useDispatch();

    useEffect(() => {
        if (errorMessage?.length > 0) window.scrollTo(0, 0);
    }, [errorMessage]);

    useEffect(() => {
        if (selectedPatientId && startTime !== 'Select' && endTime !== 'Select') {
            setLoginDisabled(false);
        }
        else {
            setLoginDisabled(true);
        }
    }, [selectedPatientId, startTime, endTime]);

    useEffect(() => {
        clearTimeout(errorTimeoutRef.current);
        if (MRN.length > 1) {
            const selectedPatients = patients.filter(patient => patient.ptMRN === MRN);
            if (selectedPatients.length) {
                const selectedPatient = selectedPatients[0];
                setSelectedPatientId(selectedPatient.ptID);
                setPatientName(selectedPatient.ptFirstName + ' ' + selectedPatient.ptLastName);
                setVisitingHours(selectedPatient);
                setAutoAnswer(selectedPatient.ptAutoAnswer);
                setErrorMessage('');
                const formatted = selectedPatient.patientContacts?.map(v => ({ 
                    email: v.pcEmail, 
                    phoneNumber: v.pcPhone, 
                    name: v.pcName,
                    id: v.pcID
                })); 
                setContacts(formatted);
            }
            else {
                setSelectedPatientId(null);
                setPatientName('');
                setStartTime('Select');
                setEndTime('Select');
                errorTimeoutRef.current = setTimeout(() => {
                    setErrorMessage('Invalid MRN');
                }, 2500);
            }
        }
        else {
            setPatientName('');
        }
    }, [MRN]);
	
    useEffect(async () => {
        setLoading(true);
        const patients = await fetchPatients();        
        if (patients) setPatients(patients.patientList);
        setLoading(false);
        return () => clearTimeout(errorTimeoutRef.current);
    }, []);

    const updateMRN = (event) => {
        setMRN(event.target.value);
    };

    const createPatient = () => {
        history.push('/newpatient');
    };

    const setVisitingHours = (patient) => {
        const ssStart = patient.patientVisitingHours.ssStart;
        const ssEnd = patient.patientVisitingHours.ssEnd;
        const ssIndefinite = patient.patientVisitingHours.ssIndefinite;
        let startDate = new Date(ssStart * 1000);
        var start = startDate.getHours();
        let mins = ('0' + startDate.getMinutes()).slice(-2);
        if(start === 0) start = '12:' + mins +'am';
        else if(start < 12) start += (':'+ mins + 'am');
        else if(start === 12) start += (':'+ mins + 'pm');
        else { 
            start -= 12;
            start += (':'+ mins + 'pm');
        }
        
        var end = '';
        if(ssIndefinite){
            end = 'Indefinite';
        } else {
            let endDate = new Date(ssEnd * 1000);
            end = endDate.getHours();
            let mins = ('0' + endDate.getMinutes()).slice(-2);
            if(end === 0) end = '12:' + mins +'am';
            else if(end < 12) end += (':'+ mins + 'am');
            else if(end === 12) end += (':'+ mins + 'pm');
            else { 
                end -= 12;
                end += (':'+ mins + 'pm');
            }
        }
        setStartTime(start);
        setEndTime(end);
    };

    const fetchPatients = async () => {
        try {
            const patientData = await API.graphql(graphqlOperation(listPatients, { ward: wardId }), { 'Authorization': getNurseAuthToken() });
            console.log('Fetch patients success');
            const patients = patientData.data.listPatients;
            return { patientList: patients };
        } catch (err) {
            console.error(err);
            setErrorMessage('Failed to fetch patients');
        }
    };

    const updateStartTime = (event) => {
        setStartTime(event);
    };

    const updateEndTime = (event) => {
        setEndTime(event);
    };

    const updateStorePatientDetails = () => {
        dispatch(actions.setPatientId(selectedPatientId));
        dispatch(actions.setPatientName(patientName));
        dispatch(actions.setMRN(MRN));
        dispatch(actions.setStartTime(startTime));
        dispatch(actions.setEndTime(endTime));
        dispatch(actions.setVisitorList(contacts));
    };

    const nextClicked = () => {
        updateStorePatientDetails();
        history.replace('/patientsetup');
    };


    return (
        <Container>
            {errorMessage && <Notification warning message={errorMessage} />}
            <Button onClick={() => createPatient()}>Create New Patient</Button>
            <H4 style={{marginTop: '1.7rem', marginBottom: '0.6rem'}}>Select Patient &amp; Visiting Hours</H4>
            <P>This device is currently unassigned. Please select an existing patient or create a new patient file by entering the patient’s MRN.</P>
            <H5 style={{marginTop: '1.7rem', marginBottom: '0.6rem'}}>Enter Patient's MRN</H5>
            <P>Please select an existing patient or create a new patient file by entering the patient’s MRN.</P>
            <InputField style={{marginTop: '1.7rem', marginBottom: '1.4rem'}} inputHandler={updateMRN} label="MRN" placeholder="Enter Patient MRN" required type={'text'} />
            <InputField label="Patient Name" disabled={true} value={patientName} />
            <H5 style={{marginTop: '1.7rem', marginBottom: '0.6rem'}}>Confirm Virtual Visiting Hours</H5>
            <P>Virtual Visiting Hours are the start and end time that a patient can receive calls to this device.</P>
            <div style={{marginTop: '1.7rem', marginBottom: '1.4rem', width: '100%'}}>
                <Dropdown label={'Visiting hours - Start time'} selected={startTime} options={startTimes} required selectedHandler={updateStartTime} />
                <Dropdown label={'Visiting hours - End time'} selected={endTime} options={endTimes} required selectedHandler={updateEndTime} />
            </div>
            <Button onClick={() => nextClicked()} isDisabled={loginDisabled}>Next</Button>
            <FadeLoader loading={loading} color={'#000000'} css={override} />
        </Container>
    );
};

export default LoginPatient;