import { formRow } from "@aws-amplify/ui";

export const getMediaDevices = async () => {
    const constraints = {
        video: {
            height: { min: 200 },
            width: { min: 200 },
            facingMode: 'user'
        },
        audio: true
    };

    let mediaSources = [];

    await navigator.mediaDevices.getUserMedia(constraints);
    let devices = await navigator.mediaDevices.enumerateDevices();

    const video_devices = devices.filter(d => d.kind === 'videoinput');
    const frontCamera = video_devices.filter(d => d.label.toLowerCase().includes('front'));
    const audio_devices = devices.filter(d => d.kind === 'audioinput');
    const audio = audio_devices.filter(d => d.deviceId === 'default')

    if (audio.length) {
        mediaSources[0] = audio[0].deviceId;
    }
    else {
        console.error('Could not access audio');
    }

    if (frontCamera.length) {
        mediaSources[1] = frontCamera[0].deviceId;
    }
    else if (video_devices.length) {
        mediaSources[1] = video_devices[0].deviceId;
    }
    else {
        console.error('Could not access camera');
    }

    return mediaSources;
};