import React from 'react';
import { Alert, Success } from '../../../styled/components.js';
import COLOURS from '../../../styled/colours.js';
import styles from './Notification.module.scss';

const Notification = ({warning, message}) => {
    return (
        <div 
            style={{background: warning ? COLOURS.RED_WARNING : COLOURS.GREEN}} 
            className={styles.container}
        >
            { warning ? <Alert invert={warning}>!</Alert> : <Success invert={true}><img src="/tick.png" alt="tick"/></Success> }
            <div className={styles.copy}>
                { message }
            </div>
        </div>
    );
};

export default Notification;