import React, { useState, useEffect, useRef } from 'react';
import styles from './Call.module.scss';
import WaitingRoom from './WaitingRoom';
import Calling from './Calling';
import InCall from './InCall';
import { API, graphqlOperation } from 'aws-amplify';
import { getDevice, getPatientCallCount } from '../../graphql/queries';
import { updatePatient } from '../../graphql/mutations';
import * as subscriptions from '../../graphql/subscriptions';
import { getDeviceAuthToken } from '../../Shared';
import { getMediaDevices } from './callUtils.js';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from '../../store/actions/app';

const Call = () => {
    const [page, setPage] = useState(0);
    const [pexRTC] = useState(new window['PexRTC']);
    const [dialString, setDialString] = useState('');
    const [dialURL, setDialURL] = useState('');
    const [callConnected, setCallConnected] = useState(false);
    const [callerQueue, setCallerQueue] = useState([]);
    const [participants, _setParticipants] = useState([]);
    const [pin, setPin] = useState('');
    const [selfViewUrl, setSelfViewUrl] = useState('');
    const [videoSource, setVideoSource] = useState('');
    const [audioSource, setAudioSource] = useState('');
    const [isPresenting, setIsPresenting] = useState(false);
    const [presentingUrl, setPresentingUrl] = useState('');
    
    const patientName = useSelector(state => state.app.patientName);
    const patientId = useSelector(state => state.app.patientId);
    const autoAnswer = useSelector(state => state.app.autoAnswer);
    const lastCallPage = useSelector(state => state.app.callPage);

    const dispatch = useDispatch();

    const participantsRef = useRef([]);
    const setParticipants = (participants) => {
        _setParticipants(participants);
        participantsRef.current = participants;
    };

    const autoAnswerRef = useRef(autoAnswer);
    const setAutoAnswer = (state) => {
        dispatch(actions.setAutoAnswer(state));
        autoAnswerRef.current = state;
    };

    const pexipVideoRef = useRef(null);

    // Dial URL for testing
    useEffect(() => console.log('DIAL URL: ', dialURL), [dialURL]);

    useEffect(async () => {
        await getDeviceInfo();
        dispatch(actions.setNurseToken(''));
        setPage(lastCallPage);
        function alertUser() {
            console.log(pexRTC);
            pexRTC.disconnect();
        }

        let audio_source = '';
        let video_source = '';
        
        [audio_source, video_source] = await getMediaDevices();

        setAudioSource(audio_source);
        setVideoSource(video_source);

        window.addEventListener('beforeunload', alertUser);
        return () => {
            window.removeEventListener('beforeunload', alertUser);
        };
    }, []);

    useEffect(async () => {
        if (dialString) {
            await checkCallState();
            doSubscriptions();
        }
    }, [dialString]);

    useEffect(async () => {
        if (callConnected) {
            await createPexipWindow(dialString);
        }
    }, [callConnected]);
    
    useEffect(() => {
        if (page === 0 && (callerQueue.length || participants.length)) {
            setPage(2); 
            dispatch(actions.setCallPage(2));
        }
        if (!callerQueue.length && !participants.length && pexRTC.state !== 'DISCONNECTING' && pexRTC.state !== 'IDLE') {
            hangupCall(); 
            setPage(0);
            dispatch(actions.setCallPage(0));
        }
    }, [callerQueue, participants]);

    const getDeviceInfo = async () => {
        try {
            const deviceData = await API.graphql(graphqlOperation(getDevice), { 'Authorization': getDeviceAuthToken() });
            const device = deviceData.data.getDevice;
            console.log(device);
            setDialString(device.ssDialString);
            setPin(device.dvPin);
            // https://visit.pexcompute.com/
            setDialURL(`https://${process.env.REACT_APP_DIAL_URL}/#/?conference=${device.ssDialString}&name=Tester&pin=&role=guest&muteMicrophone=true&muteCamera=false&callType=video&extension=&bandwidth=1920`);
        } catch (err) {
            console.error(err);
        }
    };

    const checkCallState = async () => {
        console.log('Subscription triggered');
        try {
            const patientCallCountData = await API.graphql(graphqlOperation(getPatientCallCount), { 'Authorization': getDeviceAuthToken() });
            const patientCallCount = patientCallCountData.data.getPatientCallCount;

            if (patientCallCount.count > 0 && callConnected === false) {
                setCallConnected(true);
            } 
            else if (patientCallCount.count === 0 && callConnected === true && pexRTC.state !== 'IDLE') {
                pexRTC.disconnect();
                cleanupCall();
            }
            else if (patientCallCount.count === 0 && callConnected === false) {
                cleanupCall();
            }
        } catch (err) {
            console.error(err);
        }
    };

    const cleanupCall = () => {
        setCallConnected(false);
        setParticipants([]);
        setCallerQueue([]);
        pexRTC.video_source = '';
        pexRTC.audio_source = '';
        setPage(0);
        dispatch(actions.setCallPage(0));
    };

    const doSubscriptions = async () => {
        API.graphql(graphqlOperation(subscriptions.onPatientCallChange), { 'Authorization': getDeviceAuthToken() })
            .subscribe({ next: ({provider, value}) => checkCallState(), error: error => console.warn(error)});
    };

    const participantCreated = async (participant) => {
        // I (Cameron) had to set a timeout to this, doing it too quick resulted in mixed results
        setTimeout(processParticipant(participant), 1000);
    };

    const processParticipant = (participant) => {
        if (participant.service_type === 'waiting_room') {
            console.warn(participant);

            // If the auto answer is on, admit the guest by unlocking them
            if (autoAnswerRef.current) {
                addToParticipants(participant);
                pexRTC.unlockParticipant(participant.uuid);
            } else {
                // Add code for the popup here, the answer button will unlock them
                console.log(participant.display_name + ' WANTS TO JOIN');
                // Add to Queue
                addToCallerQueue({
                    display_name: participant.display_name,
                    uuid: participant.uuid
                });
            }
        }
    };

    const participantUpdated = async (participant) => {
        const participantAlreadyInCall = participantsRef.current.filter(p => p.uuid === participant.uuid);
        if (participantAlreadyInCall.length === 0 && autoAnswerRef.current && participant.role !== 'chair') {
            console.log('Participant not in call');
            setParticipants([...participantsRef.current, participant]);
        }
    };

    const participantDeleted = async (participant) => {
        console.log('PARTICIPANT DELETED');
        const participantIdx = participantsRef.current.findIndex(p => p.uuid === participant.uuid);
        const participantsCopy = [...participantsRef.current];
        participantsCopy.splice(participantIdx, 1);
        setParticipants(participantsCopy);
    };

    const addToCallerQueue = (participant) => setCallerQueue(prevValue => [participant, ...prevValue]);
    const addToParticipants = (participant) => setParticipants([...participantsRef.current, participant]);
    // Should trigger the participantDeleted callback
    const removeParticipant = (participant) => pexRTC.disconnectParticipant(participant.uuid);
    const conferenceUpdate = async (props) => console.warn(props);
    
    const setupCall = (videoURL, pin_status) => {
        // let pin = '1234';
        setSelfViewUrl(videoURL);
        dispatch(actions.setSelfUrl(videoURL));
        pexRTC.connect(pin);
    };

    const onDisconnectCall = () => {
        // Clean up the video src
        if (pexipVideoRef.current) {
            pexipVideoRef.current.src = '';
        }
        setCallConnected(false);
    };

    const onPresentation = (setting, presenter, uuid) => {
        // if setting is true, started presenting, false, ended presentation 
        setIsPresenting(setting);
    };

    const onPresentationReload = (url) => {
        setPresentingUrl(url);  
    };

    const hangupAllCalls = () => {
        pexRTC.disconnectAll();
    };

    const medicalEndCall = () => {
        autoAnswerToggle(false);
        for (const participant of participants) {
            pexRTC.transferParticipant(participant.uuid, 'medical_end_call', 'guest', '');
        }
        hangupAllCalls();
    };

    const hangupCall = () => {
        setCallConnected(false);
        pexRTC.disconnect();
    };

    const callError = (error) => {
        console.error('Pexip error');
        console.error(error);
    };

    const autoAnswerToggle = async (toggle) => {
        const update = toggle !== null ? toggle : !autoAnswerRef.current;
        if (pexRTC && callConnected === true) pexRTC.setConferenceLock(!update);
        setAutoAnswer(update);
        try {
            const res = await API.graphql(graphqlOperation(updatePatient, { ptID: parseInt(patientId), ptAutoAnswer: update }), { 'Authorization': getDeviceAuthToken() });
            console.log(res);
        }
        catch (err) {
            console.log(err);
        }
    };

    const acceptCall = () => {
        const queue = [...callerQueue];
        const caller = queue.pop();
        pexRTC.unlockParticipant(caller.uuid);
        setCallerQueue(queue);
        addToParticipants(caller);
    };

    const rejectCall = () => {
        const queue = [...callerQueue];
        const caller = queue.pop();
        pexRTC.disconnectParticipant(caller.uuid);
        setCallerQueue(queue);
    };

    const checkCallIsConnected = (videoURL) => {
        if (pexipVideoRef.current) {
            // When the call is connected, set the video stream
            if (typeof (MediaStream) !== 'undefined' && videoURL instanceof MediaStream) {
                pexipVideoRef.current.srcObject = videoURL;
            } else {
                pexipVideoRef.current.src = videoURL;
            }
            pexRTC.setConferenceLock(!autoAnswerRef.current);
            // pexRTC.renegotiate(false);
        }
    };

    const handleIncomingCall = (answer) => answer ? acceptCall() : rejectCall();
    const handleCamera = (mute) => pexRTC.muteVideo(mute);
    const handleAudio = (mute) => pexRTC.muteAudio(mute);

    const createPexipWindow = async (uri) => {
        console.log(`pexRTC State: ${pexRTC.state}`);

        if(pexRTC.state === 'IDLE' || pexRTC.state === 'DISCONNECTING') {
            console.warn('PEXIP WINDOW CREATED');
            pexRTC.onSetup = setupCall;
            pexRTC.onConnect = checkCallIsConnected;
            pexRTC.onError = callError;
            pexRTC.onDisconnect = onDisconnectCall;
            pexRTC.onPresentation = onPresentation;
            pexRTC.onPresentationReload = onPresentationReload;

            pexRTC.onParticipantCreate = participantCreated;
            pexRTC.onParticipantUpdate = participantUpdated;
            pexRTC.onParticipantDelete = participantDeleted;
            pexRTC.onConferenceUpdate = conferenceUpdate;
            console.log(uri);
            
            pexRTC.audio_source = audioSource;
            pexRTC.video_source = videoSource;

            pexRTC.renegotiate(false);
            
            pexRTC.makeCall(`${process.env.REACT_APP_DIAL_URL}`, uri, patientName, 1920);
        }
    };


    return (
        <div className={styles.container}>
            {page === 0 && <WaitingRoom autoAnswerToggle={autoAnswerToggle} autoAnswer={autoAnswer} />}
            {page === 1 && <Calling answerCall={handleIncomingCall} incomingCallQueue={callerQueue} />}
            {page === 2 && 
                <InCall 
                    handleCall={hangupAllCalls} 
                    pexipVideoRef={pexipVideoRef}
                    incomingCallQueue={callerQueue}
                    participants={participants}
                    handleIncomingCall={handleIncomingCall}
                    handleCamera={handleCamera}
                    handleAudio={handleAudio}
                    removeParticipant={removeParticipant}
                    autoAnswerToggle={autoAnswerToggle}
                    autoAnswer={autoAnswer}
                    medicalEndCall={medicalEndCall}
                    selfViewUrl={selfViewUrl}
                    isPresenting={isPresenting}
                    presentingUrl={presentingUrl}
                />
            }
        </div>
    );
};

export default Call;

