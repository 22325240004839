import React, { useState, useEffect } from 'react';
import styles from './WaitingRoom.module.scss';
import InputField from '../../components/shared/input/InputField';
import Notification from '../../components/shared/notifications/Notification';
import Switch from '../../components/shared/switch/Switch';
import background from '../../assets/waiting-bg.jpg';
import { API, graphqlOperation } from 'aws-amplify';
import { getDeviceAuthToken } from '../../Shared';
import { useHistory } from 'react-router-dom';
import { unassignDevices } from '../../graphql/mutations';
import { useSelector } from 'react-redux';

import {
    Button,
    OutlinedButton,
    P, H4, H5, 
    Link, Info
} from '../../styled/components';

const WaitingRoom = ({autoAnswerToggle, autoAnswer}) => {
    const history = useHistory();
    const [notification, setNotification] = useState(true);
    const startTime = useSelector(state => state.app.startTime);
    const endTime = useSelector(state => state.app.endTime);
    const MRN = useSelector(state => state.app.MRN);
    const patientName = useSelector(state => state.app.patientName);
    const patientId = useSelector(state => state.app.patientId);

    useEffect(() => {		
        const timeout = setTimeout(() => {
            setNotification(false);
        }, 4000);

        return () => clearTimeout(timeout);
    }, []);

    const changeSettings = () => {
        history.push({pathname: '/patientsetup', from: 'waitingRoom' });
    };

    const unassign = async () => {
        const unassignParameters = {
            ptID: patientId
        };
        console.log(unassignParameters);
        try {
            const assignResponse = await API.graphql(graphqlOperation(unassignDevices, unassignParameters), { 'Authorization': getDeviceAuthToken() });
            console.log(assignResponse);
        }
        catch (err) {
            console.error(err);
        }
        history.replace('login');
    };

    return (
        <div className={styles.container}>
            <div style={{marginBottom: '2rem'}}>
                <div className={styles.header}>
                    <img src="/logo.png" className={styles.logo}/>
                    <img src="/visitation-logo-blue.png" className={styles.logo}/>
                    <div className={styles.row}>
                        <Link className={styles.link}>Help</Link>
                        <Info>?</Info>
                    </div>
                </div>
                <div className={styles.button_row}>
                    <OutlinedButton style={{marginRight: '0.5rem'}} onClick={changeSettings}>Change patient settings</OutlinedButton>
                    <Button style={{backgroundColor: '#c53040'}} onClick={unassign}>Unassign Device</Button>
                </div>
            </div>
            <div className={styles.sectionTop}>
                {notification && <Notification message={'Visitation Patient file has been updated.'} />}
                <H4>Welcome to Your Virtual Waiting Room</H4>
                <div className={styles.row}>
                    <div className={styles.inputContainer}>
                        <InputField label={'Patient\'s Name'} value={patientName} disabled={true} />
                    </div>
                    <div style={{width: '1rem'}}></div>
                    <div className={styles.inputContainer}>
                        <InputField label={'MRN'} value={MRN} disabled={true} />
                    </div>
                </div>
                <div className={styles.row}>
                    <H5>Your visiting hours are:</H5>
                    <P style={{margin: '0 0 0 1rem'}}>{startTime} - {endTime}</P>
                </div>
                <H5>Enable Auto-Answer for incoming calls</H5>
                <div className={styles.row}>
                    <P style={{fontSize: '0.77rem', margin: '0 4rem 0 0'}}>
						When the switch is green, Auto-Answer is ON and all visitor calls made to the patient’s device during the chosen visiting hours will be accepted automatically. This can be turned on and off at anytime.
                    </P>
                    <Switch checked={autoAnswer} onChange={autoAnswerToggle} />
                </div>
            </div>
            <div style={{backgroundImage: `url(${background})`, flexDirection: 'column'}} className={styles.background}>
                <div className={styles.h2}>
					Your nominated visitors have been notified<br/>
					that they can visit you virtually and will appear<br/>
					here when they’re ready to visit
                </div>
            </div>
        </div>
    );
};

export default WaitingRoom;
