import React from 'react';
import {
    Input,
    Alert
} from '../../../styled/components.js';
import styles from './InputField.module.scss';

const InputField = ({ label, value, placeholder, required, alert, inputHandler, type, style, index, disabled, showLabel = true }) => {

    return (
        <div className={styles.container} style={style}>
            {showLabel && <div className={styles.label}>{label}{required && <sup>*</sup>}</div>}
            <div className={styles.inputContainer}>
                <Input
                    onChange={(event)=>{inputHandler(event, index);}}
                    value={value}
                    placeholder={placeholder}
                    type={type || 'text'}
                    disabled={disabled}
                />
                {alert && <Alert className={styles.alert}>!</Alert>}
            </div>
        </div>
    );
};

export default InputField;
