import React from 'react';
import styles from './Calling.module.scss';
import { css } from '@emotion/react';

import FadeLoader from 'react-spinners/FadeLoader';
import {
    CallButton
} from '../../styled/components';

const override = css`
	margin-left:25px;
	margin-top:25px;
	position:absolute;
	left:50%;
	top:50%;
	width:200;
	height:200;
	transform:scale(2)
`;

const Calling = ({answerCall, incomingCallQueue}) => {
    return (
        <div className={styles.container}>
            <img className={styles.logo} src="./logo-white.png" alt="logo" />
            <div className={styles.message}>{incomingCallQueue[0].name}<br/>is calling</div>
            <FadeLoader loading={true} color={'#FFFFFF'} css={override} />
            <div className={styles.buttonContainer}>
                <CallButton green onClick={() => answerCall(true)}><img src="./phone-answer.png"  alt="Answer"/></CallButton>
                <CallButton onClick={() => answerCall(false)}><img src="./phone-end.png"  alt="Answer"/></CallButton>
            </div>
        </div>
    );
};

export default Calling;
