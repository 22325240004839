import React from 'react';
import styles from './Breadcrumbs.module.scss';
import { Link, P } from '../../../styled/components.js';
import { useLocation, useHistory } from 'react-router-dom';


const Breadcrumbs = () => {
    const history = useHistory();
    const url = useLocation();
    const patientSelectPage = url.pathname === '/loginpatient';
    const newPatientPage = url.pathname === '/newpatient'; 
    const patientSetupPage = url.pathname === '/patientsetup'; 

    const redirect = (path) => {
        history.push(path);
    };

    return (
        <div className={styles.container}>
            {(patientSelectPage || newPatientPage || patientSetupPage) && <div className={styles.breadcrumbs}>
                <Link onClick={()=>redirect('/location')}>Choose Location</Link> 
                <img src="arrow-up.png" className={styles.arrow}/>
                {patientSelectPage && <P>Select Patient &amp; Visiting Hours </P>}
                {newPatientPage && <P>Create New Patient </P>}
                {patientSetupPage && <>
                    <Link onClick={()=>redirect('/loginpatient')}>Select Patient</Link> 
                    <img src="arrow-up.png" className={styles.arrow}/>
                    <P>Send Invites &amp; Finish Patient Setup</P>
                </>}
            </div>}
        </div>
    );
};

export default Breadcrumbs;