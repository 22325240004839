import React, { useState, useEffect } from 'react';
import styles from './PatientSetup.module.scss';
import {
    Button,
    Container,
    P, H4, H5, Link, OutlinedButton
} from '../../styled/components';
import Switch from '../../components/shared/switch/Switch';
import Dropdown from '../../components/shared/dropdown/Dropdown';
import InputField from '../../components/shared/input/InputField';
import MultiInputRow from '../../components/shared/multiInputRow/MultiInputRow';
import VisitorInfoRow from '../../components/shared/visitorInfoRow/VisitorInfoRow';
import { useHistory, useLocation } from 'react-router-dom';
import { API, graphqlOperation } from 'aws-amplify';
import { createPatientContact, updatePatientContact, updatePatient, createSession, assignDevice, updateSession } from '../../graphql/mutations'; 
import { getDeviceAuthToken, getNurseAuthToken } from '../../Shared';
import Notification from '../../components/shared/notifications/Notification';
import FadeLoader from 'react-spinners/FadeLoader';
import { css } from '@emotion/react';
import { phone } from 'phone';

import * as actions from '../../store/actions/app';
import { useDispatch, useSelector } from 'react-redux';
import { makeTimes, getTimestampToTheHour } from '../../utils';
const override = css`
    position: fixed;
    left: 50%;
    top: 50%;
`;

const PatientSetup = () => {
    const history = useHistory();
    const empty = [{email: '', phoneNumber: '', name: ''}];
    const [newVisitors, setNewVisitors] = useState(empty);
    const [visitorsToRemove, setVisitorsToRemove] = useState([]);
    const [fail, setFail] = useState(false);
    const [failMessage, setFailMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const [visitors, setVisitors] = useState(useSelector(state => state.app.visitorList)?.map(v => ({ ...v, edit: false })) || []);

    const MRN = useSelector(state => state.app.MRN);
    const patientId = useSelector(state => state.app.patientId);
    const patientName = useSelector(state => state.app.patientName);
    const autoAnswer = useSelector(state => state.app.autoAnswer);
    const startTime = useSelector(state => state.app.startTime);
    const endTime = useSelector(state => state.app.endTime);
    const deviceToken = useSelector(state => state.app.deviceToken);

    // used for patient edit only
    const startTimes = makeTimes();
    const endTimes = ['Indefinite', ...makeTimes()];
    const [startTimeLocal, setStartTimeLocal] = useState('');
    const [endTimeLocal, setEndTimeLocal] = useState('');


    const location = useLocation();
    const dispatch = useDispatch();

    useEffect(() => {
        window.scrollTo(0, 0);
        setStartTimeLocal(startTime);
        setEndTimeLocal(endTime);
    }, []);

    useEffect(() => {
        if (fail) window.scrollTo(0, 0);
    }, [fail]);

    const createContacts = async (contacts) => {
        if (!contacts.length) return true;
        try {
            Promise.all(contacts.map(v => {
                const contactInfo = {
                    ptID: patientId,
                    pcName: v.name || '' ,
                    pcPhone: v.phoneNumber || '',
                    pcEmail: v.email || ''
                };
                const token = location?.from === 'waitingRoom' ? getDeviceAuthToken() : getNurseAuthToken(); // use device token if edit patient 
                return API.graphql(graphqlOperation(createPatientContact, contactInfo), { 'Authorization': token }); 
            }));
            return true;
        } catch (err) {
            setFailMessage('Failed to create contacts');
            console.error(err);
            return false;
        }
    };

    const updateContacts = async (contacts, remove=false) => {
        if (!contacts.length) return true;  
        try {
            Promise.all(contacts.map(v => {
                const contactInfo = {
                    pcID: v.id,
                    pcName: v.name || '' ,
                    pcPhone: v.phoneNumber || '',
                    pcEmail: v.email || '',
                    pcArchived: remove
                };
                return API.graphql(graphqlOperation(updatePatientContact, contactInfo), { 'Authorization': getDeviceAuthToken() });
            }));
            return true;
        } catch (err) {
            setFailMessage('Failed to update contacts');
            console.error(err);
            return false;
        }
    };

    const nextClicked = async () => {
        if(disableNext()) return;
        setLoading(true);

        let filteredNew = newVisitors.filter(v => v.email !== '' || v.phoneNumber !== ''); 
        let filteredOld = visitors.filter(v => v.email !== '' || v.phoneNumber !== ''); 
        let filteredEdited = filteredOld.filter(v => v.edit); 
        const allVisitors = [...filteredNew, ...filteredOld];
    
        const requests = makeRequests(filteredNew, filteredEdited);
        
        const updatesSuccessful = await Promise.all(requests);
        console.log(updatesSuccessful);
        setLoading(false);
        let checker = arr => arr.every(v => v === true);
        if(checker(updatesSuccessful)) {
            dispatch(actions.setVisitorList(allVisitors));
            history.replace('/call');
        }
        else {
            setFail(true);
        }
    };

    const makeRequests = (filteredNew, filteredEdited) => {
        if(location?.from === 'waitingRoom') {
            if(startTimeLocal !== startTime || endTimeLocal !== endTime) {
                return [createContacts(filteredNew), updateContacts(filteredEdited), updateContacts(visitorsToRemove, true), updateVisitingHours()];
            } 
            return [createContacts(filteredNew), updateContacts(filteredEdited), updateContacts(visitorsToRemove, true)];
        } else {
            return [createContacts(filteredNew), updateContacts(filteredEdited), updateContacts(visitorsToRemove, true), createSessionReq(), assignDeviceReq()];
        }
    };

    const updateVisitingHours = async () => {
        const ssStart = getTimestampToTheHour(startTimeLocal);
        const ssEnd = getTimestampToTheHour(endTimeLocal) || 0;
        const indefinite = ssEnd ? false : true;
        
        try {
            var sessionParameters = {
                ptID: patientId,
                ssStart: ssStart,
                ssEnd: ssEnd,
                ssIndefinite: indefinite,
                ssArchived: false
            };

            const updateSessionResponse = await API.graphql(graphqlOperation(updateSession, sessionParameters), { 'Authorization': getDeviceAuthToken() });
            console.log(updateSessionResponse);
            dispatch(actions.setStartTime(startTimeLocal));
            dispatch(actions.setEndTime(endTimeLocal));
            return true;
        } catch (err) {
            setFailMessage('Failed to update session');
            console.error(err);
            return false;
        }
    };

    const createSessionReq = async () => {
        const ssStart = getTimestampToTheHour(startTime);
        const ssEnd = getTimestampToTheHour(endTime) || 0;
        const indefinite = ssEnd ? false : true;
        
        try {
            var sessionParameters = {
                ptID: patientId,
                ssStart: ssStart,
                ssEnd: ssEnd,
                ssIndefinite: indefinite,
                ssArchived: false
            };

            const sessionResponse = await API.graphql(graphqlOperation(createSession, sessionParameters), { 'Authorization': getDeviceAuthToken() });
            console.log(sessionResponse);
            dispatch(actions.setDialString(sessionResponse.data.createSession.ssDialString));
            dispatch(actions.setAutoAnswer(autoAnswer));
            return true;
        } catch (err) {
            setFailMessage('Failed to create session');
            console.error(err);
            return false;
        }
    };

    const assignDeviceReq = async () => {
        try {
            var assignParameters = {
                jwt: deviceToken,
                patientId: patientId
            };

            console.warn(assignParameters);

            const assignResponse = await API.graphql(graphqlOperation(assignDevice, assignParameters), { 'Authorization': getDeviceAuthToken() });
            console.log(assignResponse);
            return true;
        } catch (err) {
            setFailMessage('Failed to assign device to patient');
            console.error(err);
            return false;
        }
    };

    const updateVisitorEmail = (event, i) => {
        let updated = [...visitors];
        updated[i].email = event.target.value;
        setVisitors(updated);
    };

    const updateVisitorPhone = (event, i) => {
        let updated = [...visitors];
        let number = event.target.value === '' ? '' : '+' + event.target.value;
        updated[i].phoneNumber =  event.target.value === '' ? '' : phone(number).phoneNumber;
        setVisitors(updated);
    };

    const invalid = (msg) => {
        setFail(true);
        window.scrollTo(0, 0);
        setFailMessage(msg);
    };

    const updateVisitorName = (event, i) => {
        let updated = [...visitors];
        updated[i].name = event.target.value;
        setVisitors(updated);
    };

    const updateNewVisitorEmail = (event, i) => {
        let updated = [...newVisitors];
        updated[i].email = event.target.value;
        setNewVisitors(updated);
    };

    const updateNewVisitorPhone = (event, i) => {
        let updated = [...newVisitors];
        let number = event.target.value === '' ? '' : '+' + event.target.value;
        updated[i].phoneNumber =  event.target.value === '' ? '' : phone(number).phoneNumber;
        setNewVisitors(updated);
    };

    const updateNewVisitorName = (event, i) => {
        let updated = [...newVisitors];
        updated[i].name = event.target.value;
        setNewVisitors(updated);
    };
    
    const addVisitorRow = () => {
        let updated = [...newVisitors];
        updated.push(empty[0]);
        setNewVisitors(updated);
    };

    const editClicked = (event, i) => {
        let updated = [...visitors];
        updated[i].edit = true;
        setVisitors(updated);
    };

    const removeClicked = (event, i) => {
        let updated = [...visitors];
        const contactToRemove = updated.splice(i, 1);
        setVisitors(updated);
        setVisitorsToRemove(prevState => [...prevState, contactToRemove[0]]);
    };

    const updateAutoAnswer = async () => {
        const update = !autoAnswer;
        dispatch(actions.setAutoAnswer(update));
        const params = {
            ptID: patientId,
            ptAutoAnswer: update
        };
        try {
            const res = await API.graphql(graphqlOperation(updatePatient, params), { 'Authorization': getDeviceAuthToken() });
            console.log(res);
        }
        catch (err) {
            console.log(err);
        }
    };

    const disableNext = () => {
        let isDisabled = false;
        var filteredNew = newVisitors.filter(function(v) { return !(v.email === '' && v.phoneNumber === '' && v.name === ''); }); 
        filteredNew.forEach((v) => {
            if(v.email !== '' || v.phoneNumber !== '' || v.name !== '') {
                isDisabled = isDisabled || (v.email === '' && v.phoneNumber === '') || v.name === '';
                if(isDisabled) invalid('Visitor information requires name and one of email or phone number');
                if(v.phoneNumber !== '') {
                    isDisabled = isDisabled || !phone(v.phoneNumber).isValid;
                    if(!phone(v.phoneNumber).isValid) invalid('Incorrect number, please check the number the number is formatted properly eg +61 XXX XXX XXX');
                }
            }  
        });
        var filteredOld = visitors.filter(function(v) { return !(v.email === '' && v.phoneNumber === '' && v.name === ''); }); 
        filteredOld.forEach((v) => {
            if(v.email !== '' || v.phoneNumber !== '' || v.name !== '') {
                isDisabled = isDisabled || (v.email === '' && v.phoneNumber === '');
                if(v.phoneNumber !== '') {
                    isDisabled = isDisabled || !phone(v.phoneNumber).isValid;
                    if(!phone(v.phoneNumber).isValid) invalid('Incorrect number, please check the number the number is formatted properly eg +61 XXX XXX XXX');
                }
            }  
        });
        return isDisabled;
    };

    const updateStartTime = (event) => {
        setStartTimeLocal(event);
    };

    const updateEndTime = (event) => {
        setEndTimeLocal(event);
    };

    return (
        <Container className={styles.container}>
            { fail && <Notification warning message={failMessage} />}
            <H4 className={styles.mrgnSml}>Send Invitations &amp; Finish Patient Setup</H4>
            <P className={styles.mrgn}>Please review the patient’s Auto-Answer settings and list of nominated visitors to complete the setup. When you click ‘Send Invitations &amp; Finish Setup’, the patient’s list of nominated visitors will be informed of the visiting hours via their chosen contact method(s).</P>
            <div className={`${styles.flex} ${styles.mrgn}`}>
                <InputField style={{marginRight: '0.3rem'}} label="MRN" value={MRN} disabled  />
                <InputField label="Patient's Name" value={patientName} disabled  />
            </div>
            <H5 className={styles.mrgnSml}>Enable Auto-Answer for incoming calls</H5>
            <div className={`${styles.flex} ${styles.mrgn}`}>
                <P style={{marginRight: '2rem'}}>When the switch is green, Auto-Answer is ON and all visitor calls made to the patient’s device during the chosen visiting hours will be accepted automatically. This can be turned on and off at anytime.</P>
                <Switch checked={autoAnswer} onChange={()=>updateAutoAnswer()}/>
            </div>
            {visitors.length > 0 && <>
                <H5 className={styles.mrgnSml}>Managing Existing Nominated Visitor List</H5>
                <P className={styles.mrgn}>Remove an existing nominated visitor by clicking on “Remove” on the corresponding row or edit their contact details by clicking ‘Edit.'</P>
            </>}
            {visitors.map((v, i) => <div style={{width:'100%'}} key={i+'row'}>
                {v.edit ? (
                    <MultiInputRow index={i} 
                        email={v.email}
                        phoneNumber={v.phoneNumber}
                        name={v.name}
                        emailHandler={updateVisitorEmail} 
                        phoneHandler={updateVisitorPhone} 
                        nameHandler={updateVisitorName}/>
                ):
                    (<VisitorInfoRow index={i} name={v.name} 
                        email={v.email} 
                        phoneNumber={v.phoneNumber} 
                        editHandler={editClicked} 
                        removeHandler={removeClicked}/>)
                }
            </div>)}
            <H5 className={styles.mrgnSml}>Add Nominated Visitors</H5>
            <P className={styles.mrgn}>Please enter the visitor’s name and their email address and/or phone number to add them as a nominated visitor. They’ll be send an invitation with the visiting hours.</P>
            {newVisitors.map((v, i) =>
                <MultiInputRow key={'row'+i} index={i} 
                    emailHandler={updateNewVisitorEmail} 
                    phoneHandler={updateNewVisitorPhone} 
                    nameHandler={updateNewVisitorName}
                    showLabels={i===0}/>
            )}
            <Link bold className={styles.mrgn} onClick={addVisitorRow}>+ Add another contact</Link>
            {location?.from === 'waitingRoom' &&
                <>
                    <H5 className={styles.mrgnSml}>Virtual Visiting Hours</H5>
                    <P className={styles.mrgn}>Virtual Visiting Hours are the start and end time that a patient can receive calls to this device. </P>
                    <Dropdown label={'Visiting hours - Start time'} selected={startTimeLocal} options={startTimes} selectedHandler={updateStartTime} required={true}/>
                    <Dropdown label={'Visiting hours - End time'} selected={endTimeLocal} options={endTimes} selectedHandler={updateEndTime} required={true}/>
                </> 
            }
            {location?.from === 'waitingRoom' ?
                <div className={styles.buttonContainer}>
                    <Button onClick={() => nextClicked()}>Send Invitations &amp; Finish Setup</Button>
                    <OutlinedButton style={{marginLeft: '0.25rem'}} onClick={()=>{history.goBack();}}>Cancel</OutlinedButton>
                </div> :
                <Button onClick={() => nextClicked()}>Send Invitations &amp; Finish Setup</Button>
            }
            <FadeLoader loading={loading} css={override}/>
        </Container>
    );
};

export default PatientSetup;