import { 
    SET_DISTRICT,
    SET_HOSPITAL,
    SET_WARD,
    SET_WARD_ID
} from '../constants';

export const setDistrict = (district) => {	
    return {
        type: SET_DISTRICT,
        payload: district
    };
};

export const setHospital = (hospital) => {	
    return {
        type: SET_HOSPITAL,
        payload: hospital
    };
};

export const setWard = (ward) => {	
    return {
        type: SET_WARD,
        payload: ward
    };
};

export const setWardId = (wardId) => {	
    return {
        type: SET_WARD_ID,
        payload: wardId
    };
};