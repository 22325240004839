// Location
export const SET_DISTRICT = 'SET_DISTRICT';
export const SET_HOSPITAL = 'SET_HOSPITAL';
export const SET_WARD = 'SET_WARD';
export const SET_WARD_ID = 'SET_WARD_ID';

// App
export const SET_NURSE_TOKEN = 'SET_NURSE_TOKEN';
export const SET_DEVICE_TOKEN = 'SET_DEVICE_TOKEN';
export const SET_DEVICE_ID = 'SET_DEVICE_ID';
export const SET_MRN = 'SET_MRN';
export const SET_PATIENT_ID = 'SET_PATIENT_ID';
export const SET_PATIENT_NAME = 'SET_PATIENT_NAME';
export const SET_START_TIME = 'SET_START_TIME';
export const SET_END_TIME = 'SET_END_TIME';
export const SET_VISITOR_LIST = 'SET_VISITOR_LIST';
export const SET_DIAL_STRING = 'SET_DIAL_STRING';
export const SET_AUTO_ANSWER = 'SET_AUTO_ANSWER';
export const SET_CALL_PAGE = 'SET_CALL_PAGE';
export const SET_SELF_URL = 'SET_SELF_URL';
