import { 
    SET_DISTRICT,
    SET_HOSPITAL,
    SET_WARD,
    SET_WARD_ID
} from '../constants';

const initialState = JSON.parse(localStorage.getItem('location')) || {
    district: 'Select',
    hospital: 'Select',
    ward: 'Select',
    wardId: -1,
};

const updateLocalStorage = (state) => {
    localStorage.setItem('location', JSON.stringify(state));
    return state;
};

export const location = (state = initialState, action = {}) => updateLocalStorage(reducer(state, action));

const reducer = (state, action) => {
    switch(action.type) {
    case SET_DISTRICT:
        return {...state, district: action.payload};
    case SET_HOSPITAL:
        return {...state, hospital: action.payload};
    case SET_WARD:
        return {...state, ward: action.payload};
    case SET_WARD_ID: {
        return {...state, wardId: action.payload};
    }
    default: 
        return state;
    }
};