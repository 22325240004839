import React, { useEffect, useState } from 'react';
import styles from './Header.module.scss';
import { Info, Link, OutlinedButton, P } from '../../../styled/components.js';
import { useLocation, useHistory } from 'react-router-dom';
import { API, graphqlOperation } from 'aws-amplify';
import { getDeviceAuthToken } from '../../../Shared';
import { unassignDevices } from '../../../graphql/mutations';
import Breadcrumbs from '../breadcrumbs/Breadcrumbs';
import Modal from '../modal/Modal';
import { useSelector } from 'react-redux';

const Header = () => {
    const history = useHistory();
    const url = useLocation();
    const district = useSelector(state => state.location.district);
    const hospital = useSelector(state => state.location.hospital);
    const ward = useSelector(state => state.location.ward);
    const patientId = useSelector(state => state.app.patientId);
    const showLocation = url.pathname !== '/location' && url.pathname !== '/'  && url.pathname !== '/login' && url.pathname !== '/landing'; 
    const fromPatient = url?.from === 'waitingRoom';
    const [showHeader, setShowHeader] = useState(true);
    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
        const blackList = ['/call', '/calling', '/waitingroom', '/incall'];
        setShowHeader(!blackList.includes(url.pathname));
    }, [url]);

    const redirect = () => {
        history.push('/location');
    };

    const logout = () => {
        unassign();
        history.push('/');
    };

    const unassign = async () => {
        const ptID = patientId;
        if (ptID) {
            try {
                const assignResponse = await API.graphql(graphqlOperation(unassignDevices, { ptID }), { 'Authorization': getDeviceAuthToken() });
                console.log(assignResponse);
            }
            catch (err) {
                console.error(err);
            }
        }
    };

    if (showHeader) {
        return (
            <div style={{marginBottom: '2rem'}}>
                <div className={styles.container}>
                    <img src="/logo.png" className={styles.nsw_logo}/>

                    <img src="/visitation-logo-blue.png" className={styles.logo}/>
                
                    <div className={styles.flex}>
                        <Link className={styles.link} onClick={()=>{setShowModal(true);}}>Help</Link>
                        <Info>?</Info>
                        {(showLocation && !fromPatient) && <OutlinedButton style={{width:'7rem', marginLeft: '1rem', height:'3rem'}} onClick={logout}>Sign Out</OutlinedButton>}
                    </div>
                </div>
                {showLocation && 
                    <div className={styles.info}>
                        <P>Location: {district} | {hospital} | {ward}</P>
                        {!fromPatient && <Link italic onClick={redirect}>Not the right place? Choose another location</Link>}
                    </div>
                }
                {(showLocation && !fromPatient) && <Breadcrumbs/>}
                {showModal && <Modal 
                    className={styles.modalC}
                    closeBtn={ () => { setShowModal(false); } }
                    contentHTML={
                        <object data="/pdf.pdf" type="application/pdf" style={{height:'100%'}}>
                            <iframe src="/pdf.pdf&embedded=true"></iframe>
                        </object>
                    }
                />}
            </div>
        );
    }
    else {
        return <></>;
    }
};

export default Header;