export const getNurseAuthToken = () => {
    var token = 'nurse-'; // If this is a null or '' the request will fail=

    if(localStorage.getItem('app')) {
        token = `nurse-${JSON.parse(localStorage.getItem('app')).nurseToken}`;
    }

    return token;
};

export const getDeviceAuthToken = () => {
    var token = 'device-'; // If this is a null or '' the request will fail=

    if(localStorage.getItem('app')) {
        token = `device-${JSON.parse(localStorage.getItem('app')).deviceToken}`;
    }

    return token;
};


