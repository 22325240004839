import React, { useState, useEffect } from 'react';
import styles from './Login.module.scss';
import { API, graphqlOperation } from 'aws-amplify';
import { doLogin } from '../../graphql/mutations';
import { getNurseAuthToken, getDeviceAuthToken } from '../../Shared';
import { useHistory } from 'react-router-dom';
import InputField from '../../components/shared/input/InputField';
import Notification from '../../components/shared/notifications/Notification';
import { registerDevice } from '../../graphql/mutations';
import {
    Button,
    Container,
    P, H4
} from '../../styled/components';
import COLOURS from '../../styled/colours';
import FadeLoader from 'react-spinners/FadeLoader';
import { css } from '@emotion/react';

import {
    setNurseToken,
    setDeviceToken,
    setDeviceId,
    setCallPage,
} from '../../store/actions/app';
import { useDispatch } from 'react-redux';
const override = css`
    position: fixed;
    left: 50%;
    top: 50%;
`;

const Login = () => {
    const history = useHistory();
    const [username, setUsername] = useState('pexip');
    const [password, setPassword] = useState('PexP45522');
    const [loading, setLoading] = useState(false);
    const [fail, setFail] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setNurseToken(''));
        //dispatch(setDeviceToken(''));
        dispatch(setCallPage(0));
        registerDeviceReq();
    }, []);

    const passwordChange = (event) => {
        setPassword(event.target.value);
    };

    const usernameChange = (event) => {
        setUsername(event.target.value);
    };

    const registerDeviceReq = async () => {
        try {
            const registerDeviceResponse = await API.graphql(graphqlOperation(registerDevice), { 'Authorization': getDeviceAuthToken() });

            if (registerDeviceResponse.data.registerDevice.body.success === true) {
                dispatch(setDeviceToken(registerDeviceResponse.data.registerDevice.body.jwt));
                dispatch(setDeviceId(registerDeviceResponse.data.registerDevice.body.deviceId));
                console.log('Device registration success');
            } else {
                console.error('Device registration failure');
                setErrorMessage('Failed to register device');
            }
        } catch (err) {
            console.error(err);
            setErrorMessage('Failed to register device');
        }
    };


    const loginClicked = async () => {
        setLoading(true);
        try {
            var loginParameters = {
                username: username,
                password: password,
                domain: process.env.REACT_APP_LOGIN_DOMAIN
            };
            const loginResponse = await API.graphql(graphqlOperation(doLogin, loginParameters), { 'Authorization': getNurseAuthToken() });
            setLoading(false);
            if (loginResponse.data.doLogin.body.success === true) {
                dispatch(setNurseToken(loginResponse.data.doLogin.body.jwt));
                setFail(false);
                history.push('/location');
            } else {
                setErrorMessage('Incorrect username or password');
                setFail(true);
            }
        } catch (err) {
            console.error(err);
        }
    };

    return (
        <Container>
            { fail && <Notification warning message={errorMessage} />}
            <div className={styles.loginContainer}>
                <div className={styles.loginGrid}>
                    <H4 className={styles.mrgnSml}>Welcome to Visitation</H4>
                    <P className={styles.mrgn}>Please log in to begin</P>
                    <div className={styles.mrgn}>                    
                        <InputField value={username} inputHandler={usernameChange} label="User ID" placeholder="User ID" required />
                    </div>
                    <InputField value={password} type={'password'} inputHandler={passwordChange} label="Password" placeholder="password" required />
                    <FadeLoader color={COLOURS.BLUE} loading={loading} css={override}/>
                </div>
                <Button onClick={loginClicked} isDisabled={username ==='' || password===''} >Log in</Button>
            </div>
        </Container>
    );
};

export default Login;