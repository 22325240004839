import styles from './Modal.module.scss';
import React from 'react';
import { P, H4, Button } from '../../../styled/components';
import COLOURS from '../../../styled/colours';

const Modal = ({buttonLabel1, buttonLabel2, handleButton1, handleButton2, title, copy, contentHTML, closeBtn}) => {
    return (
        <div className={styles.modal}>
            <div className={styles.container}>
                {closeBtn && <div onClick={closeBtn} className={styles.closeBtn} style={{backgroundColor: COLOURS.BLUE}}>X</div>}
                <H4 className={styles.font}>{title}</H4>
                {copy && <P style={{marginBottom: 0}}>{copy}</P>}
                {contentHTML}
                <div className={styles.flex}>
                    {buttonLabel1 && <Button onClick={handleButton1}>{buttonLabel1}</Button>}
                    {buttonLabel2 && <Button style={{backgroundColor: '#c53040', marginLeft: '0.5rem'}} onClick={handleButton2}>{buttonLabel2}</Button>}</div>
            </div>
        </div>
    );
};

export default Modal;
