import { 
    SET_NURSE_TOKEN,
    SET_DEVICE_TOKEN,
    SET_DEVICE_ID,
    SET_MRN,
    SET_PATIENT_ID,
    SET_PATIENT_NAME,
    SET_START_TIME,
    SET_END_TIME,
    SET_VISITOR_LIST,
    SET_DIAL_STRING, 
    SET_AUTO_ANSWER,
    SET_CALL_PAGE,
    SET_SELF_URL,
} from '../constants';

const initialState = JSON.parse(localStorage.getItem('app')) || {
    nurseToken: '',
    deviceToken: '',
    deviceId: '',
    MRN: '',
    patientId: -1,
    patientName: '',
    startTime: '',
    endTime: '',
    visitorList: [],
    dialString: '',
    autoAnswer: false,
    callPage: 0,
    selfUrl: {},
};

const updateLocalStorage = (state) => {
    localStorage.setItem('app', JSON.stringify(state));
    return state;
};

export const app = (state = initialState, action = {}) => updateLocalStorage(reducer(state, action));

const reducer = (state, action) => {
    switch(action.type) {
    case SET_NURSE_TOKEN: {
        return {...state, nurseToken: action.payload};
    }
    case SET_DEVICE_TOKEN: {
        return {...state, deviceToken: action.payload};
    }
    case SET_DEVICE_ID: {
        return {...state, deviceId: action.payload};
    }
    case SET_MRN: {
        return {...state, MRN: action.payload};
    }
    case SET_PATIENT_ID: {
        return {...state, patientId: action.payload};
    }
    case SET_PATIENT_NAME: {
        return {...state, patientName: action.payload};
    }
    case SET_START_TIME: {
        return {...state, startTime: action.payload};
    }
    case SET_END_TIME: {
        return {...state, endTime: action.payload};
    }
    case SET_VISITOR_LIST: {
        return {...state, visitorList: action.payload};
    }
    case SET_DIAL_STRING: {
        return {...state, dialString: action.payload};
    }
    case SET_AUTO_ANSWER: {
        return {...state, autoAnswer: action.payload};
    }
    case SET_CALL_PAGE: {
        return {...state, callPage: action.payload};
    }
    case SET_SELF_URL: {
        return {...state, selfUrl: action.payload};
    }
    default: 
        return state;
    }
};