import React from 'react';
import styles from './Switch.module.scss';
import { DragSwitch } from 'react-dragswitch';
import 'react-dragswitch/dist/index.css';

const Switch = ({ checked, onChange }) => {
    return (
        <div>
            <DragSwitch checked={checked} onChange={onChange} className={styles.switch} />
        </div>
    );
};

export default Switch;
