import React, { useState, useEffect } from 'react';
import styles from './NewPatient.module.scss';
import {
    Button,
    OutlinedButton,
    Container,
    P, H4, H5, Link
} from '../../styled/components';
import Switch from '../../components/shared/switch/Switch';
import InputField from '../../components/shared/input/InputField';
import MultiInputRow from '../../components/shared/multiInputRow/MultiInputRow';
import Dropdown from '../../components/shared/dropdown/Dropdown';
import { useHistory } from 'react-router-dom';
import { createPatient, createPatientContact } from '../../graphql/mutations';
import { getNurseAuthToken } from '../../Shared';
import { makeTimes, dateToHourString } from '../../utils';
import { API, graphqlOperation } from 'aws-amplify';
import Notification from '../../components/shared/notifications/Notification';
import { phone } from 'phone';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from '../../store/actions/app';
import FadeLoader from 'react-spinners/FadeLoader';
import { css } from '@emotion/react';
const override = css`
    position: fixed;
    left: 50%;
    top: 50%;
`;

const NewPatient = () => {
    const history = useHistory();
    const empty = [{email: '', phoneNumber: '', name: ''}];
    const [MRN, setMRN] = useState('');
    const [visitors, setVisitors] = useState(empty);
    const [patientName, setPatientName] = useState('');
    const [startTime, setStartTime] = useState('Select');
    const [endTime, setEndTime] = useState('Select');
    const [autoAnswer, setAutoAnswer] = useState(false);
    const [fail, setFail] = useState(false);
    const [failMessage, setFailMessage] = useState('');
    const [loading, setLoading] = useState(false);

    const wardId = useSelector(state => state.location.wardId);

    const dispatch = useDispatch();

    useEffect(() => {
        let now = new Date();
        let time = dateToHourString(now);
        setStartTime(time);
        setEndTime(time);
    }, []);

    useEffect(() => {
        if (fail) window.scrollTo(0, 0);
    }, [fail]);

    const startTimes = makeTimes();
    const endTimes = ['Indefinite', ...makeTimes()];

    const updateStartTime = (event) => {
        setStartTime(event);
    };

    const updateEndTime = (event) => {
        setEndTime(event);
    };

    const updateMRN = (event) => {
        setMRN(event.target.value);
    };

    const updatePatientName = (event) => {
        setPatientName(event.target.value);
    };

    const createPatientReq = async () => {
        const fName = patientName.split(' ')[0] || '';
        const lName = patientName.split(' ')[1] || '';
        try {
            const patientReq = { 
                ptAutoAnswer: autoAnswer,
                ptFirstName: fName,
                ptLastName: lName,
                ptMRN: MRN,
                ptArchived: false,
                lcID: wardId
            };
            const createResponse = await API.graphql(graphqlOperation(createPatient, patientReq), { 'Authorization': getNurseAuthToken() });
            console.log('Create patient success: ', createResponse);
            return createResponse.data.createPatient.ptID;
        } catch (err) {
            console.error(err);
            return -1;
        }
    };

    const createContacts = async (contacts, patientId) => {
        if (!contacts.length) return true;
        try {
            const ids = Promise.all(contacts.map(v => {
                const contactInfo = {
                    ptID: patientId,
                    pcName: v.name || '' ,
                    pcPhone: v.phoneNumber || '',
                    pcEmail: v.email || ''
                };
                console.log('Create contact successful');
                return API.graphql(graphqlOperation(createPatientContact, contactInfo), { 'Authorization': getNurseAuthToken() });
            }));
            return ids;
        } catch (err) {
            console.error(err);
            return false;
        }
    };

    const updateStorePatientDetails = (patientId, responseList) => {
        dispatch(actions.setPatientId(patientId));
        dispatch(actions.setPatientName(patientName));
        dispatch(actions.setMRN(MRN));
        dispatch(actions.setStartTime(startTime));
        dispatch(actions.setEndTime(endTime));
        dispatch(actions.setAutoAnswer(autoAnswer));

        const formatted = responseList.map(v => ({ 
            email: v.data.createPatientContact.pcEmail, 
            phoneNumber: v.data.createPatientContact.pcPhone, 
            name: v.data.createPatientContact.pcName,
            id: v.data.createPatientContact.pcID
        })); 
        dispatch(actions.setVisitorList(formatted));
    };

    const nextClicked = async () => {
        if(disableNext()) return;
        setLoading(true);
        const pid = await createPatientReq();
        if (pid !== -1) {
            let filtered = visitors.filter(v => v.email !== '' || v.phoneNumber !== ''); 
            const createContactsResult = await createContacts(filtered, pid);
            if (createContactsResult) {
                setLoading(false);
                updateStorePatientDetails(pid, createContactsResult);
                history.replace('/patientsetup');
            }
            setFail(true);
        }
        setLoading(false);
        setFailMessage('Setup was unsuccessful');
        setFail(true);
    };

    const cancelClicked = () => {
        history.push('/loginpatient');
    };

    const updateVisitorEmail = (event, i) => {
        let updated = [...visitors];
        updated[i].email = event.target.value;
        setVisitors(updated);
    };

    const updateVisitorPhone = (event, i) => {
        let updated = [...visitors];
        let number = event.target.value === '' ? '' : '+' + event.target.value;
        updated[i].phoneNumber =  event.target.value === '' ? '' : phone(number).phoneNumber;
        setVisitors(updated);
    };

    const updateVisitorName = (event, i) => {
        let updated = [...visitors];
        updated[i].name = event.target.value;
        setVisitors(updated);
    };
    
    const addVisitorRow = () => {
        let updated = [...visitors];
        updated.push(empty[0]);
        setVisitors(updated);
    };

    const invalid = (msg) => {
        setFailMessage(msg);
        setFail(true);
        window.scrollTo(0, 0);
    };

    const disableNext = () => {
        if(startTime === 'Select' || endTime === 'Select') {
            invalid('Please select visiting hours'); 
            return true;
        }
        let isDisabled = !MRN || !patientName;
        var filtered = visitors.filter(function(v) { return !(v.email === '' && v.phoneNumber === '' && v.name === ''); }); 
        isDisabled = isDisabled || filtered.length === 0;
        filtered.forEach((v)=>{
            if(v.email !== '' || v.phoneNumber !== '' || v.name !== '') {
                isDisabled = isDisabled || (v.email === '' && v.phoneNumber === '') || v.name === '';
                if(isDisabled) invalid('Visitor information requires name and one of email or phone number');
                if(v.phoneNumber !== '') {
                    isDisabled = isDisabled || !phone(v.phoneNumber).isValid;
                    if(!phone(v.phoneNumber).isValid) invalid('Incorrect number, please check the number the number is formatted properly eg +61 XXX XXX XXX');
                }
            }  
        });
        return isDisabled;
    };
    return (
        <Container className={styles.container}>
            { fail && <Notification warning message={failMessage} />}
            <H4 className={styles.mrgnSml}>Create New Patient</H4>
            <P className={styles.mrgn}>Enter the patient’s MRN and Patient’s Name</P>
            <div className={`${styles.flex} ${styles.mrgn}`}>
                <InputField style={{marginRight: '0.3rem'}}  inputHandler={updateMRN} label="MRN" placeholder="Enter Patient MRN" required  />
                <InputField inputHandler={updatePatientName} label="Patient's Name" placeholder="Enter Patient First and Last Name" required  />
            </div>
            <H5>Enable Auto-Answer for incoming calls</H5>
            <div className={`${styles.flex} ${styles.mrgn}`}>
                <P style={{marginRight: '2rem'}}>When the switch is green, Auto-Answer is ON and all visitor calls made to the patient’s device during the chosen visiting hours will be accepted automatically. This can be turned on and off at anytime.</P>
                <Switch checked={autoAnswer} onChange={(e)=>setAutoAnswer(e)}/>
            </div>
            <H5 className={styles.mrgnSml}>Add Nominated Visitors</H5>
            <P className={styles.mrgn}>Please enter the visitor’s email address and/or phone number to add them as a nominated visitor. It’s optional to add the visitor’s name. An invite will be sent to them via SMS and/or email once you click “Add Visitor.”</P>
            {visitors.map((v, i) =>
                <MultiInputRow key={'row'+i} 
                    index={i} 
                    emailHandler={updateVisitorEmail} 
                    phoneHandler={updateVisitorPhone} 
                    nameHandler={updateVisitorName}
                    showLabels={i===0}/>
            )}
            <Link bold className={styles.mrgn} onClick={addVisitorRow}>+ Add another contact</Link>
            <H5 className={styles.mrgnSml}>Confirm Virtual Visiting Hours</H5>
            <P className={styles.mrgn}>Virtual Visiting Hours are the start and end time that a patient can receive calls to this device. </P>
            <Dropdown label={'Visiting hours - Start time'} selected={startTime} options={startTimes} selectedHandler={updateStartTime} required={true}/>
            <Dropdown label={'Visiting hours - End time'} selected={endTime} options={endTimes} selectedHandler={updateEndTime} required={true}/>
            <div className={styles.flex}>
                <Button onClick={() => nextClicked()}>Next</Button>
                <OutlinedButton style={{width: '30%'}} className={styles.cancel} onClick={() => cancelClicked()}>Cancel</OutlinedButton>
            </div>
            <FadeLoader loading={loading} css={override}/>
        </Container>
    );
};

export default NewPatient;